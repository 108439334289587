<template>
  <div class="transaction-menu">
    <template v-if="links.length > 0">
      <nav class="side expander">
        <div v-for="type in headers" :key="type" class="header-wrapper">
          <h2>{{ type }}</h2>
          <hr class="main-hr" />
          <ul class="block-list">
            <template v-for="link in links">
              <template v-if="link.type == type">
                <v-tooltip top :key="link.name" :disabled="!link.disabled">
                  <template v-slot:activator="{ on }">
                    <li
                      :data-testid="link.name"
                      :key="link.name"
                      v-on:click="transactionScreen(link)"
                      v-bind:class="{ 'disabled-link': link.disabled }"
                      v-on="on"
                    >
                      {{ link.name }}
                    </li>
                  </template>
                  <span>{{ disabledMessage(link) }}</span>
                </v-tooltip>
              </template>
            </template>
          </ul>
        </div>
      </nav>
    </template>
  </div>
</template>
<script>
import vehicleFunctions from "@/mixins/vehicleFunctions.js";
import transactionFunctions from "@/mixins/transaction.mixin.js";
import { mapGetters } from "vuex";
import transactionTypes from "@/assets/jsonScaffolds/transactionTypes.json";

export default {
  name: "transactionsMenu",
  mixins: [vehicleFunctions, transactionFunctions],
  data() {
    return {
      currentlySelected: "",
      user: this.$store.getters.userObject
    };
  },
  props: {
    isFromORBack: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  methods: {
    transactionScreen(link) {
      if (link.disabled) {
        return;
      }
      this.$store.commit("transactionType", link.action);
      if (link.type === "Placard" && link.action !== "New Placard") {
        this.$router.push({
          name: "DirectTransactionChange",
          params: {
            placard: this.processObj.placard.controlNo
          }
        });
      } else if (link.action === "Online Renewal") {
        this.$api
          .renewalSearch("?vin=" + this.processObj.vehicle.vin)
          .then(result => {
            const renewal = result[0];
            this.loadRenewalTransaction(renewal);
          });
      } else {
        this.startTransaction(link.action, link.transactionRequest); // start a transaction request using the links specific vehicle record if it exists, otherwise use the vehicle record stored in the store (processObj)
      }
    },
    disabledMessage(link) {
      if (
        link.action === "Registration Renewal" &&
        this.processObj.courtesyRenewalsCount > 0
      ) {
        return "Courtesy renewal limit reached";
      }
      if (this.processObj.hasIncomplete) {
        return "This vehicle has an incomplete transaction";
      }
    }
  },
  computed: {
    ...mapGetters({
      pendingTransactionRequest: "pendingTransactionRequest",
      pendingTransaction: "pendingTransaction",
      isFeatureEnabled: "isFeatureEnabled",
      transactionType: "transactionType",
      processObj: "processObj"
    }),
    headers: function () {
      const r = [];
      for (let i = 0; i < this.links.length; i++) {
        if (!r.includes(this.links[i].type)) r.push(this.links[i].type);
      }
      return r;
    },
    navigation: function () {
      const r = [];
      for (let i = 0; i < this.links.length; i++) {
        if (this.links[i].action == "jump" || this.links[i].action == "show")
          r.push(this.links[i]);
      }
      return r;
    },
    links() {
      if (
        this.processObj.transaction != undefined &&
        this.processObj.transaction.isDeficientFinish != undefined
      ) {
        return [];
      }
      const defaultLinks = [
        {
          name: "Correction",
          type: "Title",
          action: "Correction of Title",
          disabled: false
        },
        {
          name: "Duplicate",
          type: "Title",
          action: "Duplicate Title",
          disabled: false
        },
        {
          name: "New Owner",
          type: "Title",
          action: "New Owner",
          disabled: false
        },
        {
          name: "New Owner Title Only",
          type: "Title",
          action: "New Owner Title Only",
          disabled: false
        },
        {
          name: "Manage Lienholders",
          type: "Title",
          action: "Manage Lienholders",
          disabled: false
        },
        {
          name: "Title Destroy or De-Title",
          type: "Title",
          action: "Title Destroy",
          disabled: false
        },
        {
          name: "Re-Title Only",
          type: "Title",
          action: "Re-Title Only",
          disabled: false
        },
        {
          name: "Re-Title and Register",
          type: "Title",
          action: "Re-Title and Register",
          disabled: false
        },
        {
          name: "Renew",
          type: "Registration",
          action: "Registration Renewal",
          disabled: false
        },
        {
          name: "Transfer This to New",
          type: "Registration",
          action: "Transfer Registration to New",
          disabled: false
        },
        // {name: 'Transfer From Existing', type: 'Registration', action: 'Transfer Registration from Existing'}, //todo, api doesnt support this yet
        {
          name: "Replace",
          type: "Registration",
          action: "Replace Plate",
          disabled: false
        },
        {
          name: "Lost/Stolen",
          type: "Registration",
          action: "Lost/Stolen Plate or Decal",
          disabled: false
        },
        {
          name: "Correction",
          type: "Registration",
          action: "Correction of Registration",
          disabled: false
        },
        {
          name: "Reprint Decal",
          type: "Registration",
          action: "Reprint Decal",
          disabled: false
        },
        {
          name: "Change Class",
          type: "Registration",
          action: "Change Plate Class",
          disabled: false
        },
        {
          name: "Temporary Permit",
          type: "Registration",
          action: "Temporary Operators Permit",
          disabled: false
        },
        {
          name: "TDR Sticker",
          type: "Registration",
          action: "TDR Sticker",
          disabled: false
        },
        {
          name: "Reassignment",
          type: "Registration",
          action: "Reassign Registration",
          disabled: false
        },
        {
          name: "Farm Permit",
          type: "Registration",
          action: "Farm Permit",
          disabled: false
        },
        {
          name: "New Placard",
          type: "Placard",
          action: "New Placard",
          disabled: false
        },
        {
          name: "Renew",
          type: "Placard",
          action: "Placard Renewal",
          disabled: false
        },
        {
          name: "Replace",
          type: "Placard",
          action: "Replace Placard",
          disabled: false
        },
        {
          name: "Delete",
          type: "Placard",
          action: "Delete Placard",
          disabled: false
        }
      ];
      let returnLinks = [];
      if (this.isFromORBack) {
        returnLinks.push({
          name: "Online Renewal",
          type: "Pending",
          action: "Online Renewal",
          disabled: false
        });
      }

      if (this.pendingTransactionRequest) {
        const transactionType =
          transactionTypes[
            this.pendingTransactionRequest.transaction.transactionType
          ];
        returnLinks.push({
          name:
            transactionType +
            (transactionType === "Title And Registration" ? " (EZ Tag)" : ""),
          type: "Pending",
          action: transactionType,
          transactionRequest: this.pendingTransactionRequest
        });
      }
      switch (this.transactionType) {
        case "Title And Registration":
        case "Title Only":
        case "Registration Only":
        case "Title With Temporary Tag":
        case "Temporary Tag Only":
          returnLinks = returnLinks.concat([
            {
              name: "Title & Registration",
              type: "New Vehicle",
              action: "Title And Registration",
              disabled: false
            },
            {
              name: "Title Only",
              type: "New Vehicle",
              action: "Title Only",
              disabled: false
            },
            {
              name: "Registration Only",
              type: "New Vehicle",
              action: "Registration Only",
              disabled: false
            },
            {
              name: "Temporary Tag Only",
              type: "New Vehicle",
              action: "Temporary Tag Only",
              disabled: false
            }
          ]);
          return returnLinks;

        case "New Registration":
        case "Add Vehicle":
        case "Restore Vehicle Record":
        case "EZ Tag":
        case "Online Renewal":
          return [];
        case "New Placard":
          if (!this._.has(this.processObj, "vehicle")) {
            return [];
          }
        //falls through
        default:
          for (let i = 0; i < defaultLinks.length; i++) {
            if (defaultLinks[i].type === "Title") {
              if (this._.has(this.processObj, "title")) {
                if (
                  this.processObj.hasIncomplete &&
                  defaultLinks[i].name !== "Title Destroy or De-Title"
                ) {
                  defaultLinks[i].disabled = true;
                }
                returnLinks.push(defaultLinks[i]);
              }
            }
            if (defaultLinks[i].type === "Registration") {
              if (this._.has(this.processObj, "registration")) {
                if (
                  this.processObj.hasIncomplete &&
                  this.processObj.courtesyRenewalsCount > 0 &&
                  defaultLinks[i].action === "Registration Renewal"
                ) {
                  defaultLinks[i].disabled = true;
                }
                returnLinks.push(defaultLinks[i]);
              }
            }
            if (defaultLinks[i].type === "Placard") {
              if (
                !this._.has(this.processObj, "vehicle") &&
                defaultLinks[i].action !== "New Placard"
              ) {
                returnLinks.push(defaultLinks[i]);
              }
              if (
                this._.has(this.processObj, "vehicle") &&
                defaultLinks[i].action === "New Placard" &&
                (!this._.has(this.processObj, "ownership") ||
                  (this._.has(this.processObj, "ownership") &&
                    this.processObj.ownership.nameCode !== "3"))
              ) {
                returnLinks.push(defaultLinks[i]);
              }
            }
          }

          if (this.isFeatureEnabled("mvUndercover"))
            returnLinks.push({
              name: "Undercover",
              type: "Registration",
              action: "Undercover Registration",
              disabled: false
            });
          return returnLinks;
      }
    }
  }
};
</script>

<style scoped>
.transaction-menu {
  padding-top: 15px;
  z-index: 4;
  margin-left: 20px;
  /** We need some extra space because the back to details button covers some options up */
  padding-bottom: 150px;
}

.transaction-menu ul {
  list-style-type: none;
}

.main-hr {
  margin-top: 5px;
  color: var(--nav-background);
}

.transaction-menu ul li {
  padding: 5px 5px 5px 0px;
  cursor: pointer;
  border-bottom: 1px solid var(--borders-backgrounds);
}

.transaction-menu ul li:last-child {
  border-bottom: none;
}

ul.block-list {
  padding: 0;
  list-style-type: none;
}

.selected {
  background-color: #f4f7fc;
}

.transaction-menu ul li.disabled-link {
  color: rgb(187, 187, 187);
  cursor: default;
}
/* for some reason without this chrome will wait til a click to render the side bar  https://stackoverflow.com/questions/29013035/inserted-dom-content-not-displaying-in-chrome-without-resize*/
.header-wrapper {
  overflow: auto;
}
</style>
